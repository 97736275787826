import "./style.css";
import * as dat from "lil-gui";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

import { saveAs } from "file-saver";
import * as JSZip from "jszip";

/**
 * Save image frame utils
 */

let imageList = [];
let maskList = [];

const clearBatch = () => {
  imageList = [];
  maskList = [];
};

const downloadBatch = () => {
  const zip = new JSZip();
  const imagesFolder = zip.folder("images");
  const masksFolder = zip.folder("masks");
  imageList.forEach((image, i) => {
    imagesFolder.file("image_" + i + ".png", image);
  });
  maskList.forEach((mask, j) => {
    masksFolder.file("mask_" + j + ".png", mask);
  });

  zip
    .generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {
        level: 9
      }
    })
    .then(function (blob) {
      saveAs(blob, "test.zip");
    });
};

const saveImage = () => {
  const canvas = document.getElementById("canvas");
  scene.getObjectByName("axesHelper").visible = false;
  scene.getObjectByName("lightHelper").visible = false;
  renderer.render(scene, camera);
  canvas.toBlob(function (blob) {
    imageList.push(blob);
    scene.getObjectByName("axesHelper").visible = true;
    scene.getObjectByName("lightHelper").visible = true;
    renderer.render(scene, camera);
  });
};

const binarizeScene = () => {
  let bottle = scene.getObjectByName("bottle");
  let cap = scene.getObjectByName("cap");
  bottle.material = new THREE.MeshBasicMaterial({ color: "#FFFFFF" });
  cap.material = new THREE.MeshBasicMaterial({ color: "#FFFFFF" });
  renderer.toneMappingExposure = 10.0;
  scene.background = new THREE.Color("#000000");
  scene.environment = null;
  scene.getObjectByName("floor").visible = false;
  scene.getObjectByName("axesHelper").visible = false;
  scene.getObjectByName("lightHelper").visible = false;
  renderer.render(scene, camera);
};

const restoreScene = () => {
  let bottle = scene.getObjectByName("bottle");
  let cap = scene.getObjectByName("cap");
  bottle.material = bottleMaterial;
  cap.material = capMaterial;
  renderer.toneMappingExposure = parseFloat(debugObject.toneMappingExposure);
  scene.getObjectByName("floor").visible = debugObject.floor;
  scene.getObjectByName("axesHelper").visible = true;
  scene.getObjectByName("lightHelper").visible = true;
  updateAllMaterials();
};

const saveMask = () => {
  binarizeScene();
  const canvas = document.getElementById("canvas");
  canvas.toBlob(function (blob) {
    maskList.push(blob);
    restoreScene();
  });
};

/**
 * Domain randomization: Viewpoints
 */
let objectAnimation = false;
const randomizePosition = () => {
  objectAnimation = !objectAnimation;
};

/**
 * Domain randomization: Lights
 */
let lightsAnimation = false;
const randomizeLight = () => {
  lightsAnimation = !lightsAnimation;
};

const generateSampleBatch = () => {
  objectAnimation = true;
  lightsAnimation = true;
  const samples = 10;

  for (let i = 1; i <= samples; i++) {
    setTimeout(() => {
      saveImage();
      binarizeScene();
      saveMask();
      restoreScene();
      if (i == samples) {
        objectAnimation = false;
        lightsAnimation = false;
        downloadBatch();
      }
      i++;
    }, (i - 1) * 1000);
  }
};

/**
 * Loaders
 */

const loader = new OBJLoader();
const cubeTextureLoader = new THREE.CubeTextureLoader();

/**
 * Textures
 */

const loadingManager = new THREE.LoadingManager();
loadingManager.onStart = () => {
  console.log("loadingManager: loading started");
};
loadingManager.onLoad = () => {
  console.log("loadingManager: loading finished");
};
loadingManager.onProgress = () => {
  console.log("loadingManager: loading progressing");
};
loadingManager.onError = () => {
  console.log("loadingManager: loading error");
};

const textureLoader = new THREE.TextureLoader(loadingManager);

// const colorTexture = textureLoader.load('/textures/checkerboard-1024x1024.png')
// const colorTexture = textureLoader.load('/textures/checkerboard-2x2.png')
const colorTexture = textureLoader.load("/bottleTextures/basecolor.jpg");
colorTexture.wrapS = THREE.MirroredRepeatWrapping;
colorTexture.wrapT = THREE.MirroredRepeatWrapping;
colorTexture.repeat.x = 5;
colorTexture.repeat.y = 5;
// colorTexture.offset.x = 0.5
// colorTexture.offset.y = 0.5
// colorTexture.rotation = Math.PI * 0.25
// colorTexture.center.x = 0.5
// colorTexture.center.y = 0.5
// colorTexture.generateMipmaps = true;
// colorTexture.generateMipmaps = false;
colorTexture.minFilter = THREE.NearestFilter;
colorTexture.magFilter = THREE.NearestFilter;

const heightTexture = textureLoader.load("/bottleTextures/height.png");
const normalTexture = textureLoader.load("/bottleTextures/normal.jpg");
normalTexture.wrapS = THREE.MirroredRepeatWrapping;
normalTexture.wrapT = THREE.MirroredRepeatWrapping;
normalTexture.repeat.x = 5;
normalTexture.repeat.y = 5;

const ambientOcclusionTexture = textureLoader.load(
  "/bottleTextures/ambientOcclusion.jpg"
);

const roughnessTexture = textureLoader.load("/bottleTextures/roughness.jpg");

/**
 * Base
 */

// Debug
const gui = new dat.GUI();
const debugObject = {
  roughness: 0.5,
  metalness: 0.5,
  capColor: "#3C3788",
  bottleColor: "#387AE2",
  backgroundColor: "#555555",
  envMap: false,
  envMapIntensity: 2.5,
  floor: true,
  toneMappingExposure: 0.33,
  focalLength: 18.545,
  clearBatch: clearBatch,
  saveImage: saveImage,
  saveMask: saveMask,
  downloadBatch: downloadBatch,
  randomizePosition: randomizePosition,
  randomizeLight: randomizeLight,
  generateSampleBatch: generateSampleBatch
};

const guiColors = gui.addFolder("Colors");
const guiMat = gui.addFolder("Materials");
const guiLights = gui.addFolder("Lights");
const guiCamera = gui.addFolder("Camera");
const guiHelper = gui.addFolder("Helpers");
const guiEnv = gui.addFolder("Environment");
const guiControls = gui.addFolder("Image Generation");

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Materials
 */

const bottleMaterial = new THREE.MeshStandardMaterial({
  side: THREE.DoubleSide,
  color: debugObject.bottleColor,
  roughness: debugObject.roughness,
  metalness: debugObject.metalness,
  aoMap: ambientOcclusionTexture,
  aoMapIntensity: 5,
  // map: colorTexture,
  normalMap: normalTexture,
  normalScale: new THREE.Vector2(0.5, 0.5)
  // displacementMap: heightTexture,
  // displacementScale: 0.01
});

const capMaterial = new THREE.MeshStandardMaterial({
  side: THREE.DoubleSide,
  color: debugObject.capColor,
  roughness: debugObject.roughness,
  metalness: debugObject.metalness,
  aoMap: ambientOcclusionTexture,
  aoMapIntensity: 5,
  // map: colorTexture,
  normalMap: normalTexture,
  normalScale: new THREE.Vector2(0.5, 0.5)
  // displacementMap: heightTexture,
  // displacementScale: 0.01
});

/**
 * Update all materials
 */
const updateAllMaterials = () => {
  if (debugObject.envMap) {
    scene.background = environmentMap;
    scene.environment = environmentMap;
  } else {
    scene.background = new THREE.Color(debugObject.backgroundColor);
    scene.environment = null;
  }

  scene.traverse(child => {
    if (
      child instanceof THREE.Mesh &&
      child.material instanceof THREE.MeshStandardMaterial &&
      child.name !== "floor"
    ) {
      if (debugObject.envMap) {
        child.material.envMap = environmentMap;
        child.material.envMapIntensity = debugObject.envMapIntensity;
      } else {
        child.material.envMap = null;
        child.material.envMapIntensity = null;
      }
      child.material.roughness = debugObject.roughness;
      child.material.metalness = debugObject.metalness;
      child.material.needsUpdate = true;
    }
  });

  scene.getObjectByName("floor").visible = debugObject.floor;
};

/**
 * Environment map
 */

const environmentMap = cubeTextureLoader.load([
  "/textures/environmentMaps/0/px.jpg",
  "/textures/environmentMaps/0/nx.jpg",
  "/textures/environmentMaps/0/py.jpg",
  "/textures/environmentMaps/0/ny.jpg",
  "/textures/environmentMaps/0/pz.jpg",
  "/textures/environmentMaps/0/nz.jpg"
]);

environmentMap.encoding = THREE.sRGBEncoding;

/**
 * Models
 */

loader.load("/bottle3.obj", obj => {
  console.log("object", obj);

  const bottle = obj.children[0];
  const cap = obj.children[1];

  bottle.castShadow = true;
  bottle.receiveShadow = true;

  cap.castShadow = true;
  cap.receiveShadow = true;

  bottle.geometry.setAttribute(
    "uv2",
    new THREE.BufferAttribute(bottle.geometry.attributes.uv.array, 2)
  );
  cap.geometry.setAttribute(
    "uv2",
    new THREE.BufferAttribute(cap.geometry.attributes.uv.array, 2)
  );

  bottle.name = "bottle";
  cap.name = "cap";

  bottle.material = bottleMaterial;
  cap.material = capMaterial;

  const planeGeometry = new THREE.PlaneGeometry(100, 100);
  const planeMaterial = new THREE.MeshStandardMaterial({
    side: THREE.DoubleSide,
    color: "white"
  });

  const floor = new THREE.Mesh(planeGeometry, planeMaterial);
  floor.visible = debugObject.floor;
  floor.rotation.x = Math.PI / 2;
  floor.castShadow = false;
  floor.receiveShadow = true;
  floor.name = "floor";

  scene.add(cap, bottle, floor);

  updateAllMaterials();
});

/**
 * Lights
 */

const directionalLight = new THREE.DirectionalLight("#ffffff", 3);
directionalLight.castShadow = true;
directionalLight.shadow.camera.near = 1;
directionalLight.shadow.camera.far = 30;
directionalLight.shadow.mapSize.set(1024, 1024);
// directionalLight.shadow.radius = 10;
directionalLight.shadow.normalBias = 0.05;
directionalLight.position.set(0.25, 3, -2.25);
scene.add(directionalLight);

directionalLight.target = new THREE.Object3D();
directionalLight.target.position.set(0, 2, 0);
scene.add(directionalLight.target);

const axesHelper = new THREE.AxesHelper();
axesHelper.name = "axesHelper";
directionalLight.target.add(axesHelper);

// Light shadow camera helper (useful to optimize shadow camera parameters)
// const directionalLightCameraHelper = new THREE.CameraHelper(
//   directionalLight.shadow.camera
// );
// scene.add(directionalLightCameraHelper);

let lightHelper = new THREE.DirectionalLightHelper(directionalLight, 0.5);
lightHelper.name = "lightHelper";
lightHelper.update();
scene.add(lightHelper);

const ambientLight = new THREE.AmbientLight("#ffffff", 5);
scene.add(ambientLight);

/**
 * Sizes
 */

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */

// Base camera
let camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  1000
);
camera.position.set(3, 2, -1.5);
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.target.copy(directionalLight.target.position);
controls.enableDamping = true;

/**
 * Renderer
 */

const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
  powerPreference: "high-performance",
  preserveDrawingBuffer: true
});
renderer.physicallyCorrectLights = true;
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = debugObject.toneMappingExposure;
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.setClearColor(debugObject.backgroundColor);

/**
 * Animate
 */

let time = Date.now();
let clock = new THREE.Clock();

const tick = () => {
  // Time
  const currentTime = Date.now();
  const deltaTime = currentTime - time;
  time = currentTime;

  const elapsedTime = clock.getElapsedTime();

  if (objectAnimation) {
    let bottle = scene.getObjectByName("bottle");
    let cap = scene.getObjectByName("cap");
    bottle.rotateY(deltaTime * 0.001);
    bottle.rotateZ(deltaTime * 0.00025);
    cap.rotateY(deltaTime * 0.001);
    cap.rotateZ(deltaTime * 0.00025);
  }

  if (lightsAnimation) {
    directionalLight.position.x = Math.cos(elapsedTime) * 2;
    directionalLight.position.y = Math.abs(Math.sin(elapsedTime) * 3) * 1.25;
    directionalLight.position.z = Math.sin(elapsedTime) * 3;
  }

  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();

/**
 * Debug UI
 */

// Colors

guiColors.addColor(debugObject, "capColor").onChange(function () {
  const cap = scene.getObjectByName("cap");
  cap.material.color = new THREE.Color(debugObject.capColor);
});
guiColors.addColor(debugObject, "bottleColor").onChange(function () {
  const bottle = scene.getObjectByName("bottle");
  bottle.material.color = new THREE.Color(debugObject.bottleColor);
});
guiColors.addColor(debugObject, "backgroundColor").onChange(function () {
  renderer.setClearColor(debugObject.backgroundColor);
  scene.background = new THREE.Color(debugObject.backgroundColor);
  renderer.render(scene, camera);
});

// Lights

guiLights
  .add(ambientLight, "intensity")
  .min(0)
  .max(10)
  .step(0.1)
  .name("ambientIntensity");
guiLights
  .add(directionalLight, "intensity")
  .min(0)
  .max(15)
  .step(0.1)
  .name("lightIntensity");
guiLights
  .add(directionalLight.position, "x")
  .min(-10)
  .max(10)
  .step(0.01)
  .name("lightX")
  .onChange(() => {
    lightHelper.update();
  });
guiLights
  .add(directionalLight.position, "y")
  .min(-10)
  .max(10)
  .step(0.001)
  .name("lightY")
  .onChange(() => {
    lightHelper.update();
  });
guiLights
  .add(directionalLight.position, "z")
  .min(-10)
  .max(10)
  .step(0.001)
  .name("lightZ")
  .onChange(() => {
    lightHelper.update();
  });

// Camera
guiCamera.add(camera.position, "x").min(-10).max(10).step(0.1).name("cameraX");

guiCamera.add(camera.position, "y").min(-10).max(10).step(0.1).name("cameraY");
guiCamera.add(camera.position, "z").min(-10).max(10).step(0.1).name("cameraZ");

guiCamera
  .add(camera, "fov")
  .min(0)
  .max(180)
  .step(5)
  .name("fov")
  .onChange(() => {
    camera.updateProjectionMatrix();
  });

guiCamera
  .add(debugObject, "focalLength")
  .min(0)
  .max(200)
  .step(1)
  .name("Focal Length")
  .onChange(fl => {
    camera.setFocalLength(fl);
    camera.updateProjectionMatrix();
  });

// Helpers

guiHelper.add(axesHelper, "visible").name("Axes Helper");
guiHelper.add(lightHelper, "visible").name("Light Helper");

// Materials

guiMat
  .add(debugObject, "roughness")
  .min(0)
  .max(1)
  .step(0.01)
  .onChange(updateAllMaterials);

guiMat
  .add(debugObject, "metalness")
  .min(0)
  .max(1)
  .step(0.01)
  .onChange(updateAllMaterials);

// Environment

guiEnv
  .add(renderer, "toneMapping", {
    No: THREE.NoToneMapping,
    Linear: THREE.LinearToneMapping,
    Reinhard: THREE.ReinhardToneMapping,
    Cineon: THREE.CineonToneMapping,
    ACESFilmic: THREE.ACESFilmicToneMapping
  })
  .onFinishChange(() => {
    renderer.toneMapping = Number(renderer.toneMapping);
    updateAllMaterials();
  });
guiEnv.add(renderer, "toneMappingExposure").min(0).max(0.5).step(0.01);

guiEnv.add(debugObject, "envMap").onChange(updateAllMaterials);
guiEnv
  .add(debugObject, "envMapIntensity")
  .min(0)
  .max(10)
  .step(0.001)
  .onChange(updateAllMaterials);

guiEnv.add(debugObject, "floor").onChange(updateAllMaterials);

guiControls.add(debugObject, "saveImage");
guiControls.add(debugObject, "saveMask");
guiControls.add(debugObject, "clearBatch");
guiControls.add(debugObject, "downloadBatch");
guiControls.add(debugObject, "randomizePosition");
guiControls.add(debugObject, "randomizeLight");
guiControls.add(debugObject, "generateSampleBatch");
